<template>
  <div class="g19-tender-stat">
    <my-search-bar>
      <el-form :inline="true">
        <el-form-item>
          <el-date-picker
            v-model="reportDate"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyyMMdd"
            style="width: 100%;">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button @click="handleExport">导出Excel</el-button>
        </el-form-item>
      </el-form>
    </my-search-bar>
    <div class="spread-wrapper">
      <my-spread
        v-if="defaultDataList.length"
        ref="mySpread"
        :spread-info="spreadInfo"
        :default-data-list="defaultDataList"
        >
      </my-spread>
    </div>
  </div>
</template>

<script>
import g19Service from '@/services/g19Service'
import prepareMaterialListService from '@/services/prepareMaterialListService'
import auth from '@/common/auth'
import MySpread from '@/components/Spread/MySpread'
import MySearchBar from '@/components/MySearchBar/MySearchBar'

export default {
  name: 'G19TenderStat',
  components: {
    MySearchBar,
    MySpread
  },
  data () {
    return {
      spreadInfo: {
        sheetName: '材料备料日统计汇总表',
        columns: [
          {
            label: '材料名称',
            dataKey: 'prepareMaterialListName',
            width: 200
          },
          {
            label: '单位',
            dataKey: 'unit',
            width: 90
          }
        ]
      },
      defaultDataList: [],
      prepareMaterialList: [],
      tenderStatList: [],
      prepareMaterialMap: {},
      reportDate: ''
    }
  },
  watch: {
    reportDate (newVal) {
      this.getData()
    }
  },
  methods: {
    handleExport () {
      console.log(this.$refs.mySpread)
      this.$refs.mySpread.exportExcel('主要材料备料日统计汇总表1.xlsx')
    },
    generateMapData () {
      this.prepareMaterialList.forEach(item => {
        this.prepareMaterialMap[item.materialListName] = {
          prepareMaterialListGuid: item.materialListName,
          prepareMaterialListCode: item.materialListName,
          prepareMaterialListName: item.materialListName,
          parentPrepareMaterialListName: item.parentMaterialList.materialListName,
          unit: item.unit,
          sortId: item.sortId
        }
      })

      this.tenderStatList.forEach(list => {
        if (list.length !== 0) {
          const item = list[0]
          const column = {
            label: item.tenderName === 'total' ? '汇总' : item.tenderName,
            children: [
              {
                label: '备料总量',
                dataKey: `${item.tenderName}_num`,
                width: 100
              },
              {
                label: '本月计划',
                dataKey: `${item.tenderName}_currentMonthNum`,
                width: 100
              },
              {
                label: '本日完成',
                dataKey: `${item.tenderName}_currentDateNum`,
                width: 100
              },
              {
                label: '本月累计',
                dataKey: `${item.tenderName}_monthNum`,
                width: 100
              },
              {
                label: '本月累计占月计划',
                dataKey: `${item.tenderName}_monthPercent`,
                width: 100
              },
              {
                label: '全部累计',
                dataKey: `${item.tenderName}_totalNum`,
                width: 100
              },
              {
                label: '全部累计占备料总量',
                dataKey: `${item.tenderName}_totalPercent`,
                width: 100
              }
            ]
          }
          this.spreadInfo.columns.push(column)
        }
        list.forEach(item => {
          this.prepareMaterialMap[item.prepareMaterialListCode][`${item.tenderName}_num`] = item.num
          this.prepareMaterialMap[item.prepareMaterialListCode][`${item.tenderName}_currentMonthNum`] = item.currentMonthNum
          this.prepareMaterialMap[item.prepareMaterialListCode][`${item.tenderName}_currentDateNum`] = item.currentDateNum
          this.prepareMaterialMap[item.prepareMaterialListCode][`${item.tenderName}_monthNum`] = item.monthNum
          this.prepareMaterialMap[item.prepareMaterialListCode][`${item.tenderName}_totalNum`] = item.totalNum
          this.prepareMaterialMap[item.prepareMaterialListCode][`${item.tenderName}_monthPercent`] = item.monthPercent
          this.prepareMaterialMap[item.prepareMaterialListCode][`${item.tenderName}_totalPercent`] = item.totalPercent
        })
      })

      for (let key of Object.keys(this.prepareMaterialMap)) {
        const item = this.prepareMaterialMap[key]
        this.defaultDataList.push(item)
      }
      console.log('defaultDataList', this.prepareMaterialMap, this.defaultDataList)

      this.$loading().close()
    },
    getEngineeringList () {
      prepareMaterialListService.list({}, { tenderGuid: auth.getUserInfo().tenderGuid })
        .then(res => {
          if (res.data.code === 1) {
            this.prepareMaterialList = res.data.data.filter(item => item.parentId !== 0)
            this.generateMapData()
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '加载失败，请重试！'
          })
        })
    },
    getData () {
      this.$myLoading()
      this.tenderStatList = []
      this.defaultDataList = []
      this.spreadInfo.columns = [
        {
          label: '材料名称',
          dataKey: 'prepareMaterialListName',
          width: 200
        },
        {
          label: '单位',
          dataKey: 'unit',
          width: 90
        }
      ]
      if (!this.reportDate) {
        this.$loading().close()
        return
      }
      g19Service.getTenderStatSummary(this.reportDate, auth.getUserInfo().projectGuid)
        .then(res => {
          if (res.data.code === 1) {
            this.tenderStatList = res.data.data

            this.getEngineeringList()
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '加载失败，请重试！'
          })
        })
    }
  },
  created () {
    this.getData()
  }
}
</script>

<style scoped lang="scss">
.g19-tender-stat {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .spread-wrapper {
    flex: 1;
  }
}
</style>
